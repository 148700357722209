<script>
//import SettingBool from './_SettingBool';

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    //SettingBool,
  },
  methods: {
    getBoolValue: function(name) {
      if(this.data.settings && this.data.settings.bools) {
        var result = false;
        this.data.settings.bools.forEach((el) => { 
          if(el.name === name)
          {
            result = el.value;  
            return;
          }
        });
        return result;
      }
      return false;
    },
  },
  computed: {
  },
};
</script>

<template>
  <div class="mb-3">
    <h5 class="card-title mb-3">Episode settings:</h5>
    <ul class="list-unstyled mb-0">

      <!-- <SettingBool name="notifications_email_all" title="Email notifications" memo="Send me all notifications by email" :value="getBoolValue('notifications_email_all')"></SettingBool> -->

    </ul>
  </div>
</template>