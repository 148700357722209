<script>
import AccountSettings from "./_Settings.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    AccountSettings,
  },
  methods: {
  }
};
</script>

<template>
  <div class="card mt-xxl">
    <div class="card-header">
      <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
        <li class="nav-item active">
          <a class="nav-link text-body active" data-bs-toggle="tab" href="#settings" role="tab" aria-selected="true">
            <i class="far fa-envelope"></i> Settings </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-4">
      <div class="tab-content">
        <div class="tab-pane active" id="settings" role="tabpanel">
          <AccountSettings v-bind:data="data"></AccountSettings>
        </div>
      </div>
    </div>
  </div>
</template>