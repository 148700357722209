<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import appConfig from "../../../../app.config";

import axios from 'axios';
import EpisodeSidebar from "./EpisodeSidebar.vue";
import EpisodeMenuContent from "./EpisodeMenuContent.vue";
import EpisodeMenuSettings from "./EpisodeMenuSettings.vue";

export default {
  page: {
    title: "Episode",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Episode",
      menu: 'main',
      data: {
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    EpisodeSidebar,
    EpisodeMenuContent,
    EpisodeMenuSettings,
  },
  created: function () {
    this.loadingEpisode();
  },
  methods: {
    loadingEpisode() {
      axios.get('/api/management/episode/edit/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
    sidebarMenuChanged(e) {
      this.menu = e;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #sidebar>
        <EpisodeSidebar v-bind:data="data" @changed-sidebar-menu="sidebarMenuChanged"></EpisodeSidebar>
      </template>
      <template #content>
        <EpisodeMenuContent v-if="menu==='main'" v-bind:data="data" @refresh-episode="loadingEpisode"></EpisodeMenuContent>
        <EpisodeMenuSettings v-if="menu==='settings'" v-bind:data="data" @refresh-episode="loadingEpisode"></EpisodeMenuSettings>
      </template>
    </Container>
  </Layout>
</template>
